var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AppLoadingSpinner',{model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("被试者属性名称")]),_c('v-card-text',[_vm._v("点击下方表格中的内容处，可修改配置")]),_c('v-data-table',{staticClass:"mx-8",attrs:{"headers":_vm.fieldConfigHeaders,"items":_vm.fieldConfigList,"item-key":"guid","sort-by":['order'],"sort-desc":[false],"disable-pagination":"","hide-default-footer":"","loading-text":"正在读取属性名称，请稍候...","no-data-text":"未找到任何属性","no-results-text":"未找到任何匹配的属性"},scopedSlots:_vm._u([{key:"item.fieldAlias",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.fieldAlias,"large":"","persistent":"","save-text":"保存","cancel-text":"取消"},on:{"update:returnValue":function($event){return _vm.$set(item, "fieldAlias", $event)},"update:return-value":function($event){return _vm.$set(item, "fieldAlias", $event)},"save":function($event){return _vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'fieldAlias',
                  item.fieldAlias
                )}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v("更新显示名称")]),_c('v-text-field',{attrs:{"single-line":"","dense":"","autofocus":"","label":"显示名称"},model:{value:(item.fieldAlias),callback:function ($$v) {_vm.$set(item, "fieldAlias", $$v)},expression:"item.fieldAlias"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(item.fieldAlias))])])]}},{key:"item.configType",fn:function(ref){
                var item = ref.item;
return [_c('v-select',{key:item.guid,staticClass:"mr-3 select-small-width",attrs:{"items":_vm.configTypeList,"disabled":!item.isConfigurable,"dense":"","solo":"","hide-details":"auto"},on:{"change":function($event){return _vm.fieldConfigChange(item.guid, item.configType, item.fieldName)}},model:{value:(item.configType),callback:function ($$v) {_vm.$set(item, "configType", $$v)},expression:"item.configType"}})]}},{key:"item.ctForShowType",fn:function(ref){
                var item = ref.item;
return [(item.isConfigurable)?_c('div',[(_vm.isSelectConfigType(item.configType))?_c('v-edit-dialog',{attrs:{"large":"","persistent":"","save-text":"保存","cancel-text":"取消"},on:{"open":function($event){_vm.originalValueRange = [].concat( item.valueRange )},"cancel":function($event){item.valueRange = _vm.originalValueRange},"close":function($event){_vm.newValueRangeItem = ''},"save":function($event){_vm.fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'valueRange',
                    JSON.stringify(item.valueRange)
                  )}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v("更新取值范围")]),_c('div',_vm._l((item.valueRange),function(val,idx){return _c('v-chip',{key:idx,staticClass:"mr-1 my-1",attrs:{"small":"","close":""},on:{"click:close":function($event){return item.valueRange.splice(idx, 1)}}},[_vm._v(" "+_vm._s(val)+" ")])}),1),_c('v-text-field',{attrs:{"single-line":"","autofocus":"","label":"输入要添加的值"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.addToValueRange(item.valueRange)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" 添加 ")],1)]},proxy:true}],null,true),model:{value:(_vm.newValueRangeItem),callback:function ($$v) {_vm.newValueRangeItem=$$v},expression:"newValueRangeItem"}})]},proxy:true}],null,true)},[_c('div',[_vm._l((item.valueRange),function(val,idx){return _c('v-chip',{key:idx,staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(val)+" ")])}),(!item.valueRange || !item.valueRange.length)?_c('span',{staticClass:"text-caption"},[_vm._v(" 点击添加取值范围 ")]):_vm._e()],2)]):(_vm.isTextConfigType(item.configType))?_c('v-edit-dialog',{attrs:{"large":"","persistent":"","save-text":"保存","cancel-text":"取消"},on:{"open":function($event){_vm.originalStrLength = item.strLength},"cancel":function($event){item.strLength = _vm.originalValueRange},"close":function($event){_vm.originalStrLength = ''},"save":function($event){return _vm.strLengthChanged(
                    item.guid,
                    _vm.originalStrLength,
                    item.fieldName
                  )}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v("更新长度限制")]),_c('v-text-field',{attrs:{"type":"number","single-line":"","autofocus":"","label":"请输入数字","placeholder":"请输入数字"},model:{value:(_vm.originalStrLength),callback:function ($$v) {_vm.originalStrLength=$$v},expression:"originalStrLength"}})]},proxy:true}],null,true)},[_c('div',[(item.strLength)?_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(("最大限制 " + (item.strLength) + " 个字符"))+" ")]):_c('span',[_vm._v(" 点击添加长度限制 ")])])]):(_vm.isNumberConfigType(item.configType))?_c('v-edit-dialog',{attrs:{"large":"","persistent":"","save-text":"保存","cancel-text":"取消"},on:{"open":function($event){_vm.applyNumRangeChange(_vm.convertNumRangeToObj(item.numRange))},"cancel":function($event){return _vm.applyNumRangeChange({ minVal: '', maxVal: '' })},"close":function($event){return _vm.applyNumRangeChange({ minVal: '', maxVal: '' })},"save":function($event){return _vm.numRangeChanged(
                    item.guid,
                    _vm.newMinVal,
                    _vm.newMaxVal,
                    item.fieldName
                  )}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v("更新数字范围")]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"type":"number","label":"最小值","max":_vm.newMaxVal},model:{value:(_vm.newMinVal),callback:function ($$v) {_vm.newMinVal=$$v},expression:"newMinVal"}}),_c('v-text-field',{attrs:{"type":"number","label":"最大值","min":_vm.newMinVal},model:{value:(_vm.newMaxVal),callback:function ($$v) {_vm.newMaxVal=$$v},expression:"newMaxVal"}})],1)]},proxy:true}],null,true)},[_c('div',[(item.numRange)?_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.numRangeShow(item.numRange))+" ")]):_c('span',[_vm._v(" 点击添加数字范围 ")])])]):_vm._e()],1):_vm._e()]}},{key:"item.isVisible",fn:function(ref){
                  var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","disabled":!item.isConfigurable},on:{"change":function($event){return _vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'isVisible',
                  item.isVisible
                )}},model:{value:(item.isVisible),callback:function ($$v) {_vm.$set(item, "isVisible", $$v)},expression:"item.isVisible"}})]}},{key:"item.isRequired",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","disabled":!item.isConfigurable},on:{"change":function($event){return _vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'isRequired',
                  item.isRequired
                )}},model:{value:(item.isRequired),callback:function ($$v) {_vm.$set(item, "isRequired", $$v)},expression:"item.isRequired"}})]}},{key:"item.isVisibleInTable",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'isVisibleInTable',
                  item.isVisibleInTable
                )}},model:{value:(item.isVisibleInTable),callback:function ($$v) {_vm.$set(item, "isVisibleInTable", $$v)},expression:"item.isVisibleInTable"}})]}},{key:"item.isVisibleInReport",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.fieldValueChanged(
                  item.guid,
                  item.fieldName,
                  'isVisibleInReport',
                  item.isVisibleInReport
                )}},model:{value:(item.isVisibleInReport),callback:function ($$v) {_vm.$set(item, "isVisibleInReport", $$v)},expression:"item.isVisibleInReport"}})]}},{key:"item.order",fn:function(ref){
                var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","dense":""}},[(item.order > 1)?_c('v-btn',{attrs:{"color":"primary","elevation":"2","icon":"","x-small":""},on:{"click":function($event){_vm.changeOrder(
                    'up',
                    item.guid,
                    item.fieldName,
                    Number(item.order)
                  )}}},[_c('v-icon',[_vm._v("mdi-transfer-up")])],1):_vm._e()],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('div',[_vm._v("---")])]),_c('v-row',{attrs:{"align":"center","dense":""}},[(item.order < _vm.fieldConfigList.length)?_c('v-btn',{attrs:{"color":"primary","elevation":"2","icon":"","x-small":""},on:{"click":function($event){_vm.changeOrder(
                    'down',
                    item.guid,
                    item.fieldName,
                    Number(item.order)
                  )}}},[_c('v-icon',[_vm._v("mdi-transfer-down")])],1):_vm._e()],1)]}}],null,true)})],1)],1)],1),_c('AppDialog',{attrs:{"persistent":"","size":"small","color":"warning","title":"是否要更改信息类型？","action-text":"确认"},on:{"confirm":function($event){return _vm.resetConfigRalateInfo(_vm.selectGuid, _vm.fieldName)},"cancelled":function($event){return _vm.dialogCancel(_vm.oldVal, _vm.selectedConfigTypeGuid)}},model:{value:(_vm.isShowChangeConfirmDialog),callback:function ($$v) {_vm.isShowChangeConfirmDialog=$$v},expression:"isShowChangeConfirmDialog"}},[_vm._v(" 更改类型，相关配置也将被清空，是否继续？ ")]),_c('AppMessageBox',{attrs:{"title":"发生错误"},model:{value:(_vm.errorMsg),callback:function ($$v) {_vm.errorMsg=$$v},expression:"errorMsg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }