<template>
  <v-container>
    <AppLoadingSpinner v-model="isLoading" />
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>被试者属性名称</v-card-title>
          <v-card-text>点击下方表格中的内容处，可修改配置</v-card-text>
          <v-data-table
            class="mx-8"
            :headers="fieldConfigHeaders"
            :items="fieldConfigList"
            item-key="guid"
            :sort-by="['order']"
            :sort-desc="[false]"
            disable-pagination
            hide-default-footer
            loading-text="正在读取属性名称，请稍候..."
            no-data-text="未找到任何属性"
            no-results-text="未找到任何匹配的属性"
          >
            <template v-slot:[`item.fieldAlias`]="{ item }">
              <v-edit-dialog
                :return-value.sync="item.fieldAlias"
                large
                persistent
                save-text="保存"
                cancel-text="取消"
                @save="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'fieldAlias',
                    item.fieldAlias
                  )
                "
              >
                <div>{{ item.fieldAlias }}</div>
                <template v-slot:input>
                  <div class="mt-4 text-h6">更新显示名称</div>
                  <v-text-field
                    single-line
                    dense
                    autofocus
                    label="显示名称"
                    v-model="item.fieldAlias"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.configType`]="{ item }">
              <!-- 测评单位不能选择字段类型 -->
              <v-select
                class="mr-3 select-small-width"
                v-model="item.configType"
                :items="configTypeList"
                :key="item.guid"
                :disabled="!item.isConfigurable"
                dense
                solo
                hide-details="auto"
                @change="
                  fieldConfigChange(item.guid, item.configType, item.fieldName)
                "
              ></v-select>
            </template>
            <template v-slot:[`item.ctForShowType`]="{ item }" ]>
              <div v-if="item.isConfigurable">
                <v-edit-dialog
                  v-if="isSelectConfigType(item.configType)"
                  large
                  persistent
                  save-text="保存"
                  cancel-text="取消"
                  @open="originalValueRange = [...item.valueRange]"
                  @cancel="item.valueRange = originalValueRange"
                  @close="newValueRangeItem = ''"
                  @save="
                    fieldValueChanged(
                      item.guid,
                      item.fieldName,
                      'valueRange',
                      JSON.stringify(item.valueRange)
                    )
                  "
                >
                  <div>
                    <v-chip
                      v-for="(val, idx) in item.valueRange"
                      :key="idx"
                      class="mr-1 my-1"
                      small
                    >
                      {{ val }}
                    </v-chip>
                    <span
                      v-if="!item.valueRange || !item.valueRange.length"
                      class="text-caption"
                    >
                      点击添加取值范围
                    </span>
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">更新取值范围</div>
                    <div>
                      <v-chip
                        v-for="(val, idx) in item.valueRange"
                        :key="idx"
                        class="mr-1 my-1"
                        small
                        close
                        @click:close="item.valueRange.splice(idx, 1)"
                      >
                        {{ val }}
                      </v-chip>
                    </div>
                    <v-text-field
                      single-line
                      autofocus
                      label="输入要添加的值"
                      v-model="newValueRangeItem"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          color="primary"
                          small
                          @click="addToValueRange(item.valueRange)"
                        >
                          <v-icon>mdi-plus</v-icon>
                          添加
                        </v-btn>
                      </template>
                    </v-text-field>
                  </template>
                </v-edit-dialog>
                <v-edit-dialog
                  v-else-if="isTextConfigType(item.configType)"
                  large
                  persistent
                  save-text="保存"
                  cancel-text="取消"
                  @open="originalStrLength = item.strLength"
                  @cancel="item.strLength = originalValueRange"
                  @close="originalStrLength = ''"
                  @save="
                    strLengthChanged(
                      item.guid,
                      originalStrLength,
                      item.fieldName
                    )
                  "
                >
                  <div>
                    <span v-if="item.strLength" class="text-caption">
                      {{ `最大限制 ${item.strLength} 个字符` }}
                    </span>
                    <span v-else>
                      点击添加长度限制
                    </span>
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">更新长度限制</div>
                    <v-text-field
                      type="number"
                      single-line
                      autofocus
                      label="请输入数字"
                      v-model="originalStrLength"
                      placeholder="请输入数字"
                    >
                    </v-text-field>
                  </template>
                </v-edit-dialog>
                <v-edit-dialog
                  v-else-if="isNumberConfigType(item.configType)"
                  large
                  persistent
                  save-text="保存"
                  cancel-text="取消"
                  @open="
                    applyNumRangeChange(convertNumRangeToObj(item.numRange))
                  "
                  @cancel="applyNumRangeChange({ minVal: '', maxVal: '' })"
                  @close="applyNumRangeChange({ minVal: '', maxVal: '' })"
                  @save="
                    numRangeChanged(
                      item.guid,
                      newMinVal,
                      newMaxVal,
                      item.fieldName
                    )
                  "
                >
                  <div>
                    <span v-if="item.numRange" class="text-caption">
                      {{ numRangeShow(item.numRange) }}
                    </span>
                    <span v-else>
                      点击添加数字范围
                    </span>
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">更新数字范围</div>
                    <div class="d-flex justify-space-between align-center">
                      <v-text-field
                        class="mr-4"
                        type="number"
                        label="最小值"
                        :max="newMaxVal"
                        v-model="newMinVal"
                      ></v-text-field>
                      <v-text-field
                        type="number"
                        label="最大值"
                        :min="newMinVal"
                        v-model="newMaxVal"
                      ></v-text-field>
                    </div>
                  </template>
                </v-edit-dialog>
              </div>
            </template>
            <template v-slot:[`item.isVisible`]="{ item }">
              <v-switch
                inset
                v-model="item.isVisible"
                @change="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'isVisible',
                    item.isVisible
                  )
                "
                :disabled="!item.isConfigurable"
              ></v-switch>
            </template>
            <template v-slot:[`item.isRequired`]="{ item }">
              <v-switch
                inset
                v-model="item.isRequired"
                @change="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'isRequired',
                    item.isRequired
                  )
                "
                :disabled="!item.isConfigurable"
              ></v-switch>
            </template>
            <template v-slot:[`item.isVisibleInTable`]="{ item }">
              <v-switch
                inset
                v-model="item.isVisibleInTable"
                @change="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'isVisibleInTable',
                    item.isVisibleInTable
                  )
                "
              ></v-switch>
            </template>
            <template v-slot:[`item.isVisibleInReport`]="{ item }">
              <v-switch
                inset
                v-model="item.isVisibleInReport"
                @change="
                  fieldValueChanged(
                    item.guid,
                    item.fieldName,
                    'isVisibleInReport',
                    item.isVisibleInReport
                  )
                "
              ></v-switch>
            </template>
            <template v-slot:[`item.order`]="{ item }">
              <v-row align="center" dense>
                <v-btn
                  v-if="item.order > 1"
                  color="primary"
                  elevation="2"
                  icon
                  x-small
                  @click="
                    changeOrder(
                      'up',
                      item.guid,
                      item.fieldName,
                      Number(item.order)
                    )
                  "
                >
                  <v-icon>mdi-transfer-up</v-icon>
                </v-btn>
              </v-row>
              <v-row align="center" dense>
                <div>---</div>
              </v-row>
              <v-row align="center" dense>
                <v-btn
                  v-if="item.order < fieldConfigList.length"
                  color="primary"
                  elevation="2"
                  icon
                  x-small
                  @click="
                    changeOrder(
                      'down',
                      item.guid,
                      item.fieldName,
                      Number(item.order)
                    )
                  "
                >
                  <v-icon>mdi-transfer-down</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <AppDialog
      v-model="isShowChangeConfirmDialog"
      persistent
      size="small"
      color="warning"
      title="是否要更改信息类型？"
      action-text="确认"
      @confirm="resetConfigRalateInfo(selectGuid, fieldName)"
      @cancelled="dialogCancel(oldVal, selectedConfigTypeGuid)"
    >
      更改类型，相关配置也将被清空，是否继续？
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import _ from "lodash";
import AppDialog from "@/components/AppDialog";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import { getUserFieldConfig, updateFieldConfig } from "@/api/fieldConfig";
import { configType } from "@/utils/constants/fieldConfig";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog
  },

  props: {
    nodeGuid: {
      type: String
    }
  },

  data() {
    return {
      isShowChangeConfirmDialog: false,
      isLoading: false,
      fieldConfigList: [],
      fieldConfigHeaders: [],
      // value range editing
      originalValueRange: [],
      newValueRangeItem: "",
      originalStrLength: "",
      // rule message editing
      originalRegexRule: {},
      newRegexRule: {},
      fieldRules: {
        fieldMaxLength: [
          val => (val || "").length < 10 || "自定义标题不能超过10个字符"
        ]
      },
      errorMsg: "",
      configTypeList: [],
      oldConfigTypeValList: [], //用于暂存原始列表
      oldVal: "", //用于暂存之前的configType
      selectedConfigTypeGuid: "", //选中ConfigTypeGuid
      newVal: "",
      newMinVal: "",
      newMaxVal: "",
      fieldName: "",
      selectGuid: ""
    };
  },

  watch: {
    nodeGuid(newGuid) {
      this.resetOldConfigTypeValList(this.fieldConfigList);
      this.fetchFieldConfigList(newGuid);
    }
  },

  methods: {
    isTextConfigType(configTypeVal) {
      return configTypeVal && configTypeVal === configType.text.value;
    },
    isNumberConfigType(configTypeVal) {
      return configTypeVal && configTypeVal === configType.number.value;
    },
    isSelectConfigType(configTypeVal) {
      return (
        configTypeVal &&
        (configTypeVal === configType.select.value ||
          configTypeVal === configType.multiselect.value)
      );
    },
    async changeOrder(directTo, fieldGuid, fieldName, orderNum) {
      try {
        this.isLoading = true;
        let newOrder = directTo === "up" ? orderNum - 1 : orderNum + 1;
        let ctItem = _.find(this.fieldConfigList, { order: newOrder });
        let configToUpdate_0 = {
          guid: ctItem.guid,
          fieldName: ctItem.fieldName
        };
        configToUpdate_0["order"] = orderNum;
        await updateFieldConfig(["order"], configToUpdate_0);

        let configToUpdate = { guid: fieldGuid, fieldName };
        configToUpdate["order"] = newOrder;
        await updateFieldConfig(["order"], configToUpdate);
        await this.fetchFieldConfigList(this.nodeGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    //确认后修改ConfigType的类型，并清空之前绑定的规则
    async resetConfigRalateInfo(orgGuid, fieldName) {
      try {
        this.isLoading = true;
        //#region 根据之前的类型，找到要清空绑定的字段
        let clearCol = "";
        if (configType[this.oldVal]) {
          clearCol = configType[this.oldVal].constraintCol || "";
        }
        //#endregion

        //#region 清空之前的数据
        let configToUpdate = { guid: orgGuid, fieldName };
        if (clearCol) {
          configToUpdate["configType"] = this.newVal;
          configToUpdate[clearCol] = null;
          await updateFieldConfig(["configType", clearCol], configToUpdate);
        } else {
          configToUpdate["configType"] = this.newVal;
          await updateFieldConfig(["configType"], configToUpdate);
        }
        //#endregion

        //#region 重置数据
        this.resetOldConfigTypeValList(this.fieldConfigList);
        this.fieldConfigList = this.fieldConfigList.map(fc => {
          if (fc.guid === orgGuid) {
            fc.configType = this.newVal;
            fc.ctForShowType = this.newVal;
            if (
              configType[this.oldVal] &&
              configType[this.oldVal].constraintCol
            ) {
              fc[configType[this.oldVal].constraintCol] = null;
            }
          }
          return fc;
        });
        this.fieldName = "";
        this.selectGuid = "";
        //#endregion
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isShowChangeConfirmDialog = false;
      this.isLoading = false;
    },
    //ConfigType的option点击修改
    fieldConfigChange(ctGuid, newCTValue, fieldName) {
      this.selectGuid = ctGuid;
      this.newVal = newCTValue;
      this.fieldName = fieldName;
      this.selectedConfigTypeGuid = ctGuid;
      this.isShowChangeConfirmDialog = true;
      this.oldVal = _.find(this.oldConfigTypeValList, {
        guid: ctGuid
      }).configType;
    },
    //取消，返回原来的选项
    dialogCancel(orgVal, orgGuid) {
      this.fieldConfigList = this.fieldConfigList.map(p => {
        if (p.guid === orgGuid) {
          p.configType = orgVal;
        }
        return p;
      });
    },
    initFieldConfigHeaders() {
      this.fieldConfigHeaders = [
        {
          text: "属性说明",
          value: "fieldDesc"
        },
        {
          text: "显示名称",
          value: "fieldAlias"
        },
        {
          text: "信息类型",
          value: "configType",
          sortable: false
        },
        {
          text: "取值范围",
          value: "ctForShowType",
          sortable: false
        },
        {
          text: "是否显示",
          value: "isVisible"
        },
        {
          text: "是否必填",
          value: "isRequired"
        },
        {
          text: "是否在案例列表中显示",
          value: "isVisibleInTable"
        },
        {
          text: "是否在报告中显示",
          value: "isVisibleInReport"
        },
        {
          text: "排序",
          value: "order"
        }
      ];
    },
    addToValueRange(valueRangeList) {
      if (this.newValueRangeItem) {
        valueRangeList.push(this.newValueRangeItem);
        this.newValueRangeItem = "";
      }
    },
    async fetchFieldConfigList(nodeGuid) {
      try {
        this.isLoading = true;
        // 加载 ConfigType
        this.configTypeList = _.values(configType);
        let orgList = await getUserFieldConfig([nodeGuid]);
        this.fieldConfigList = orgList.map(l => {
          l.ctForShowType = l.configType;
          return l;
        });
        //保存每个configType的原始记录
        this.resetOldConfigTypeValList(this.fieldConfigList);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async numRangeChanged(fieldGuid, minVal, maxVal, fieldName) {
      let validNums = true;
      if (minVal && maxVal) {
        if (parseInt(minVal) > parseInt(maxVal)) validNums = false;
      }
      if (validNums) {
        try {
          this.isLoading = true;
          let configToUpdate = { guid: fieldGuid, fieldName };
          let strObj = JSON.stringify({ minVal: minVal, maxVal: maxVal });
          configToUpdate["numRange"] = strObj;
          await updateFieldConfig(["numRange"], configToUpdate);
          this.fieldConfigList = this.fieldConfigList.map(p => {
            if (p.guid === fieldGuid) {
              p.numRange = strObj;
            }
            return p;
          });
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    },
    async strLengthChanged(fieldGuid, newVal, fieldName) {
      try {
        this.isLoading = true;
        let configToUpdate = { guid: fieldGuid, fieldName };
        configToUpdate["strLength"] = parseInt(newVal);
        await updateFieldConfig(["strLength"], configToUpdate);
        this.fieldConfigList = this.fieldConfigList.map(p => {
          if (p.guid === fieldGuid) {
            p.strLength = newVal;
          }
          return p;
        });
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    //select类型内容修改
    async fieldValueChanged(fieldGuid, fieldName, newValName, newVal) {
      try {
        this.isLoading = true;
        let configToUpdate = { guid: fieldGuid, fieldName };
        configToUpdate[newValName] = newVal;
        await updateFieldConfig([newValName], configToUpdate);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    /*
    重置原始配置，只要Guid和ConfigType
    */
    resetOldConfigTypeValList(fcList) {
      if (fcList.length > 0) {
        this.oldConfigTypeValList = [];
        _(fcList).forEach(l => {
          this.oldConfigTypeValList.push({
            guid: l.guid,
            configType: l.configType
          });
        });
      }
    },
    /*将numRange的string内容转换成对象*/
    convertNumRangeToObj(strNumRange) {
      let obj = { minVal: "", maxVal: "" };
      if (strNumRange) {
        obj = JSON.parse(strNumRange);
      }
      return obj;
    },
    applyNumRangeChange({ minVal, maxVal }) {
      this.newMinVal = minVal;
      this.newMaxVal = maxVal;
    },
    numRangeShow(numRange) {
      let strSpan = "";
      let numRangeObj = this.convertNumRangeToObj(numRange);
      if (numRangeObj.minVal) strSpan += " 最小值:" + numRangeObj.minVal;
      if (numRangeObj.maxVal) strSpan += " 最大值:" + numRangeObj.maxVal;
      return strSpan || "无限制";
    }
  },

  created() {
    this.initFieldConfigHeaders();
    this.fetchFieldConfigList(this.nodeGuid);
  }
};
</script>
