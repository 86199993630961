<template>
  <v-container>
    <AppLoadingSpinner v-model="isLoading" />
    <v-row>
      <v-col cols="12" sm="5" md="4" lg="3">
        <v-card class="fixed-card-left">
          <v-card-title>
            <span>测评单位</span>
            <v-spacer></v-spacer>
            <v-text-field
              class="node-search-field"
              v-model="nodeSearchText"
              append-icon="mdi-magnify"
              label="搜索"
              single-line
              hide-details
              clearable
              dense
            ></v-text-field>
          </v-card-title>
          <v-divider></v-divider>
          <v-treeview
            ref="nodeTreeView"
            class="fixed-tree-left py-3 px-2 text-body-2"
            transition
            :search="nodeSearchText"
            :active.sync="selectedNodeId"
            :items="nodeTreeList"
            item-key="id"
            item-text="name"
            hoverable
            activatable
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon :key="item.id">
                {{ open ? "mdi-layers-outline" : "mdi-layers" }}
              </v-icon>
            </template>
          </v-treeview>
        </v-card>
      </v-col>
      <v-col cols="12" sm="7" md="8" lg="9">
        <v-card v-if="selectedNodeGuid" class="fixed-card-right">
          <v-toolbar flat class="align-center">
            <v-toolbar-title>{{ selectedNodeName }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <AppTooltipBtn
              v-if="isClientNode"
              class="mt-2 mr-4"
              text
              color="primary"
              icon="mdi-card-bulleted-settings-outline"
              label="识别号类型"
              tooltip="添加或删除被试者识别号的类型"
              @click="isShowIdentityTypeDialog = true"
            />
            <AppTooltipBtn
              v-if="!isTopNode && isClientNode"
              class="mt-2 mr-4"
              text
              color="primary"
              icon="mdi-file-lock-outline"
              label="权限类型"
              tooltip="添加或删除可用的权限类型"
              @click="isShowPermissionDialog = true"
            />
            <AppTooltipBtn
              v-if="!isTopNode && isClientNode"
              class="mt-2 mr-4"
              text
              color="primary"
              icon="mdi-clipboard-list-outline"
              label="可用量表"
              tooltip="添加或删除此单位能使用的所有量表"
              @click="isShowNodeLbPickerDialog = true"
            />
            <AppTooltipBtn
              class="mt-2 mr-4"
              text
              color="primary"
              icon="mdi-layers-plus"
              label="新建"
              tooltip="新建下属的测评单位"
              @click="showCreateNodeDialog"
            />
            <AppTooltipBtn
              class="mt-2 mr-4"
              text
              color="error"
              icon="mdi-delete"
              label="删除"
              :disabled="isTopNode"
              :tooltip="isTopNode ? '无法删除最高单位' : '删除测评单位'"
              @click="showDeleteNodeDialog"
            />
            <template v-slot:extension>
              <v-tabs show-arrows v-model="selectedTabValue">
                <v-tab href="#tab-1" v-if="chargeShow('node_tstctrl')">
                  测量控制
                </v-tab>
                <v-tab href="#tab-2">基本信息</v-tab>
                <v-tab href="#tab-3">报告设置</v-tab>
                <v-tab href="#tab-4" v-if="chargeShow('node_fieldconfig')">
                  被试者信息显示
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items v-model="selectedTabValue">
            <v-tab-item value="tab-1" class="fixed-tab-items-right">
              <AdminConfigNodeStatistic
                :node-guid="selectedNodeGuid"
                :node-name="selectedNodeName"
              />
            </v-tab-item>
            <v-tab-item value="tab-2" class="fixed-tab-items-right">
              <AdminConfigNodeInfo :node-guid="selectedNodeGuid" />
            </v-tab-item>
            <v-tab-item value="tab-3" class="fixed-tab-items-right">
              <AdminConfigNodeReport :node-guid="selectedNodeGuid" />
            </v-tab-item>
            <v-tab-item value="tab-4" class="fixed-tab-items-right">
              <AdminConfigNodeFieldConfig
                :node-guid="selectedNodeGuid"
                class="field-config-tab-item"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <AppDialog
      v-model="isShowIdentityTypeDialog"
      persistent
      size="large"
      overflow-height="calc(100vh - 270px)"
      :title="`${selectedNodeName}的被试者识别号类型`"
      cancel-text="关闭"
    >
      <AdminConfigIdentityType
        :node-guid="selectedNodeGuid"
        :node-type="selectedNodeTypeName"
      />
    </AppDialog>
    <AppDialog
      v-model="isShowPermissionDialog"
      persistent
      size="large"
      overflow-height="calc(100vh - 270px)"
      :title="`${selectedNodeName}的被试者识别号类型`"
      cancel-text="关闭"
    >
      <AdminConfigPermissions :node-guid="selectedNodeGuid" />
    </AppDialog>
    <AppDialog
      v-model="isShowNodeLbPickerDialog"
      persistent
      size="large"
      overflow-height="calc(100vh - 270px)"
      :title="`${selectedNodeName}的可用量表设置`"
      cancel-text="关闭"
    >
      <AdminConfigLbPicker
        :node-guid="selectedNodeGuid"
        :use-lb-id="true"
        :reorderable="false"
      />
    </AppDialog>
    <AppDialog
      v-model="isShowNodeCreationDialog"
      size="small"
      color="primary"
      title="新建下属单位"
      action-text="新建"
      :loading="isBtnLoading"
      @confirm="createNewNodeFromParent"
      @closed="createNodeDialogClosed"
    >
      <v-text-field
        v-model="newNodeName"
        label="测评单位名称"
        :rules="fieldRules.newNodeName"
      ></v-text-field>
    </AppDialog>
    <AppDialog
      v-model="isShowNodeDeletionDialog"
      size="small"
      color="error"
      title="删除测评单位"
      action-text="确认删除"
      :loading="isBtnLoading"
      @confirm="deleteOneNode"
      @closed="deleteNodeDialogClosed"
    >
      <v-select
        v-model="isDeleteAllChildren"
        :items="deleteNodeModeList"
        item-value="value"
        item-text="text"
        label="选择删除模式"
        :rules="fieldRules.isDeleteAllChildren"
      ></v-select>
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AdminConfigNodeStatistic from "@/components/admin/AdminConfigNodeStatistic";
import AdminConfigNodeInfo from "@/components/admin/AdminConfigNodeInfo";
import AdminConfigNodeReport from "@/components/admin/AdminConfigNodeReport";
import AdminConfigNodeFieldConfig from "@/components/admin/AdminConfigNodeFieldConfig";
import AdminConfigLbPicker from "@/components/admin/AdminConfigLbPicker";
import AdminConfigIdentityType from "@/components/admin/AdminConfigIdentityType";
import AdminConfigPermissions from "@/components/admin/AdminConfigPermissions";
import { mapGetters } from "vuex";
import {
  fetchManageNodeTreeList,
  createNewNode,
  deleteOneNode
} from "@/api/manageNode";
import { nodeTypes } from "@/utils/constants/user";
import { adminConfigNodePagePermission } from "@/api/permission";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog,
    AppTooltipBtn,
    AdminConfigNodeStatistic,
    AdminConfigNodeInfo,
    AdminConfigNodeReport,
    AdminConfigNodeFieldConfig,
    AdminConfigLbPicker,
    AdminConfigIdentityType,
    AdminConfigPermissions
  },

  data() {
    return {
      isLoading: false,
      isBtnLoading: false,
      errorMsg: "",
      nodeTreeList: [],
      selectedNodeId: [],
      nodeSearchText: "",
      selectedNodeGuid: "",
      selectedNodeName: "",
      selectedNodeTypeName: "",
      selectedNodeChildren: [],
      selectedTabValue: "",
      // user identity type
      isShowIdentityTypeDialog: false,
      // user permissions
      isShowPermissionDialog: false,
      // node lb picker
      isShowNodeLbPickerDialog: false,
      // node create dialogs
      isShowNodeCreationDialog: false,
      newNodeName: "",
      fieldRules: {
        newNodeName: [val => (val || "").length > 0 || "测评单位名称不能为空"],
        isDeleteAllChildren: [val => val != null || "请选择一个删除模式"]
      },
      // node delete dialog
      isShowNodeDeletionDialog: false,
      isDeleteAllChildren: null
    };
  },

  watch: {
    selectedNodeId(newVal) {
      if (newVal && newVal.length) {
        let nodeId = newVal[0];
        let nodeObjFound = this.searchNodeObjById(this.nodeTreeList, nodeId);
        this.selectedNodeGuid = nodeObjFound.guid;
        this.selectedNodeName = nodeObjFound.name;
        this.selectedNodeTypeName = nodeObjFound.nodeTypeName;
        this.selectedNodeChildren = nodeObjFound.children;
      }
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid",
      nodeGuids: "user/loginNodeGuids"
    }),
    isTopNode() {
      let topNodeIndex = this.nodeTreeList.findIndex(
        node => node.guid === this.selectedNodeGuid
      );
      return topNodeIndex > -1;
    },
    isTailNode() {
      return !this.selectedNodeChildren || !this.selectedNodeChildren.length;
    },
    isClientNode() {
      return this.selectedNodeTypeName === nodeTypes.client.value;
    },
    deleteNodeModeList() {
      let deleteModeList = [
        {
          value: false,
          text: "仅删除当前的测评单位（如有的下属的测评单位，自动上升一级）"
        },
        { value: true, text: "同时删除当前的和所有的下属的测评单位" }
      ];
      // 最末端的 Node 只能删除自己
      if (this.isTailNode) {
        return [deleteModeList[0]];
      }
      // ClientNode 只能删除所有下属单位
      if (this.isClientNode) {
        return [deleteModeList[1]];
      }
      return deleteModeList;
    }
  },

  methods: {
    chargeShow(permission) {
      return adminConfigNodePagePermission(permission);
    },
    async fetchNodeTreeList() {
      try {
        this.isLoading = true;
        this.nodeTreeList = await fetchManageNodeTreeList(
          this.regionGuid,
          this.nodeGuids
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    searchNodeObjById(singleLayerNodeList, searchId) {
      if (singleLayerNodeList && singleLayerNodeList.length) {
        for (let nodeObj of singleLayerNodeList) {
          if (nodeObj.id === searchId) {
            return nodeObj;
          }
          let nodeObjFound = this.searchNodeObjById(nodeObj.children, searchId);
          if (nodeObjFound) {
            return nodeObjFound;
          }
        }
      }
      return null;
    },
    // nodeTreeUpdateAll(isExpandAll) {
    //   this.$refs.nodeTreeView.updateAll(isExpandAll);
    // },
    // ======================== Create Node ============================
    showCreateNodeDialog() {
      this.isShowNodeCreationDialog = true;
    },
    createNodeDialogClosed() {
      this.newNodeName = "";
    },
    async createNewNodeFromParent() {
      try {
        this.isBtnLoading = true;
        await createNewNode(
          this.regionGuid,
          this.selectedNodeGuid,
          this.newNodeName
        );
        // 刷新 Tree
        await this.fetchNodeTreeList();
        this.isShowNodeCreationDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    // ======================== Delete Node ============================
    showDeleteNodeDialog() {
      this.isShowNodeDeletionDialog = true;
    },
    deleteNodeDialogClosed() {
      this.isDeleteAllChildren = null;
    },
    async deleteOneNode() {
      try {
        this.isBtnLoading = true;
        await deleteOneNode(
          this.regionGuid,
          this.selectedNodeGuid,
          this.isDeleteAllChildren
        );
        // 刷新 Tree
        await this.fetchNodeTreeList();
        this.isShowNodeDeletionDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    }
  },

  created() {
    this.fetchNodeTreeList();
  }
};
</script>

<style lang="scss" scoped>
.fixed-card-left {
  overflow: auto;

  .fixed-tree-left {
    height: calc(100vh - 240px);
    min-width: 250px;
    cursor: pointer;
  }
}

.fixed-card-right {
  height: calc(100vh - 170px);
  overflow: auto;
}

.fixed-tab-items-right {
  height: calc(100vh - 282px);
  overflow: auto;
}

.lb-group-tab-item {
  min-width: 700px;
}

.field-config-tab-item {
  min-width: 1000px;
}

.node-search-field {
  max-width: 120px;
}
</style>
