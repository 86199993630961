<template>
  <div>
    <v-card flat class="mt-4 ml-4">
      <v-card-title>
        权限类型设置
        <AppTooltipBtn
          btn-class="ml-4"
          color="primary"
          icon="mdi-plus-circle"
          tooltip="添加新的权限类型"
          @click="openPermissionCreationDialog"
        />
      </v-card-title>
      <v-data-table
        class="mx-8"
        :headers="permissionTableHeaders"
        :items="nodePermList"
        item-key="guid"
        disable-sort
        disable-pagination
        hide-default-footer
        :loading="isDataTableLoading"
        loading-text="正在加载权限类型，请稍候..."
        no-data-text="未找到任何权限类型，点击“+”添加"
      >
        <template v-slot:[`item.guid`]="{ item }">
          <AppTooltipBtn
            btn-class="mr-2"
            color="error"
            icon="mdi-delete"
            tooltip="删除此权限类型"
            @click="openPermissionDeleteDialog(item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <AppDialog
      v-model="isShowPermissionCreationDialog"
      size="big"
      title="添加新的权限类型"
      color="green"
      action-text="添加"
      :loading="isDialogLoading"
      @confirm="addPermissionToNode"
      @closed="permissionCreationDialogClosed"
    >
      <v-select
        multiple
        chips
        clearable
        :items="allSuperPermList"
        item-value="name"
        item-text="dispName"
        v-model="selectedPermName"
        :loading="isSuperPermListLoading"
        persistent-hint
      ></v-select>
    </AppDialog>
    <AppDialog
      v-model="isShowPermissionDeleteDialog"
      color="error"
      size="small"
      :title="`是否确定要删除权限类型 ${actionPermName} 吗？`"
      action-text="确认删除"
      @confirm="deletePermissionFromNode"
      @closed="permissionDeleteDialogClosed"
    >
      不会影响现有的数据，如需要可重新添加。
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import {
  fetchAllSuperPermissions,
  getAllPermissionsFromNodeList,
  addPermissionListToNode,
  deleteOnePermissionFromNode
} from "@/api/role";
import _ from "lodash";

export default {
  components: {
    AppMessageBox,
    AppDialog,
    AppTooltipBtn
  },

  props: {
    nodeGuid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isDialogLoading: false,
      isDataTableLoading: false,
      isSuperPermListLoading: false,
      errorMsg: "",
      allSuperPermList: [],
      nodePermList: [],
      selectedPermName: [],
      actionPermGuid: "",
      actionPermName: "",
      permissionTableHeaders: [
        {
          text: "权限名称",
          value: "dispName"
        },
        {
          text: "权限描述",
          value: "description"
        },
        {
          text: "操作",
          value: "guid"
        }
      ],
      // dialogs
      isShowPermissionCreationDialog: false,
      isShowPermissionDeleteDialog: false
    };
  },

  watch: {
    nodeGuid() {
      this.refreshNodePermissions();
    }
  },

  methods: {
    async getAllSuperPermissions() {
      try {
        this.isSuperPermListLoading = true;
        let allPermList = await fetchAllSuperPermissions();
        this.allSuperPermList = _.differenceBy(
          allPermList,
          this.nodePermList,
          "dispName"
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isSuperPermListLoading = false;
    },
    async refreshNodePermissions() {
      try {
        this.isDataTableLoading = true;
        this.nodePermList = await getAllPermissionsFromNodeList([
          this.nodeGuid
        ]);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDataTableLoading = false;
    },
    // ======================== Add Permission ================================
    openPermissionCreationDialog() {
      this.getAllSuperPermissions();
      this.isShowPermissionCreationDialog = true;
    },
    permissionCreationDialogClosed() {
      this.selectedPermName = [];
      this.refreshNodePermissions();
    },
    async addPermissionToNode() {
      try {
        this.isDialogLoading = true;
        await addPermissionListToNode(this.nodeGuid, this.selectedPermName);
        this.isShowPermissionCreationDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    // ======================== Delete Permission ================================
    openPermissionDeleteDialog(permItem) {
      this.actionPermGuid = permItem.guid;
      this.actionPermName = permItem.dispName;
      this.isShowPermissionDeleteDialog = true;
    },
    permissionDeleteDialogClosed() {
      this.actionPermGuid = "";
      this.actionPermName = "";
      this.refreshNodePermissions();
    },
    async deletePermissionFromNode() {
      try {
        this.isDialogLoading = true;
        await deleteOnePermissionFromNode(this.actionPermGuid);
        this.isShowPermissionDeleteDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    }
  },

  created() {
    this.refreshNodePermissions();
  }
};
</script>
